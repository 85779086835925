/* Use this file to define print styles.

//Force backgroud images/color
/* * {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}*/
/* line 10, src/sass/print.scss */
* {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact; }

/* line 15, src/sass/print.scss */
.bk-quote,
.wrapper-text,
.hero-title,
.date,
.title,
.js-summary li,
.bk-mention,
.img-wrapper,
.text-wrapper,
li {
  opacity: 1 !important;
  transform: translate(0px, 0px) !important; }

/* line 29, src/sass/print.scss */
.bk-video-wrapper {
  display: none !important; }

/* line 33, src/sass/print.scss */
.page-break-before {
  page-break-before: always; }

/* line 37, src/sass/print.scss */
.bk-discover {
  color: black !important; }
  /* line 40, src/sass/print.scss */
  .bk-discover .title-w-curve {
    color: black !important; }

/* line 45, src/sass/print.scss */
.hero-title,
.intro,
.date {
  color: black !important; }

/* line 51, src/sass/print.scss */
.bottom-hero {
  background-color: white; }

/* line 57, src/sass/print.scss */
.hero-edito.bg-color,
.hero-xl.bg-color {
  background-color: white !important; }

/* line 63, src/sass/print.scss */
header,
footer,
.btn-back-to-top,
.bk-floatings-btns {
  display: none !important; }

/* line 70, src/sass/print.scss */
.global-wrapper {
  padding-top: 0 !important; }

/* line 76, src/sass/print.scss */
.page-content-wrapper .site-content.-main {
  padding-top: 20px !important; }

/*ACCORDION*/
/* line 83, src/sass/print.scss */
.bk-accordion {
  display: block !important; }
  /* line 86, src/sass/print.scss */
  .bk-accordion .accordion-content {
    display: block !important; }

/* line 94, src/sass/print.scss */
.bk-stepper .stepper-list .stepper-step .step-title,
.bk-stepper .stepper-list .stepper-step .step-number {
  font-size: 14px !important; }

/* line 99, src/sass/print.scss */
.bk-stepper .stepper-list .stepper-step .step-number {
  padding: 18px 10px !important; }

/* line 107, src/sass/print.scss */
.bk-desc-offer .offer-title {
  display: block !important; }
  /* line 110, src/sass/print.scss */
  .bk-desc-offer .offer-title img {
    display: block !important;
    margin: 0 auto !important; }

/* line 121, src/sass/print.scss */
.bk-contact-help .grid,
.bk-accordion .grid,
bk-news-event .grid {
  display: block !important; }
  /* line 125, src/sass/print.scss */
  .bk-contact-help .grid .grid__cell,
  .bk-accordion .grid .grid__cell,
  bk-news-event .grid .grid__cell {
    width: 100% !important; }

/* line 132, src/sass/print.scss */
.bk-contact-banner {
  color: #000 !important; }

/* line 137, src/sass/print.scss */
.styled-select:before, .styled-select:after {
  display: none !important; }
  /* line 141, src/sass/print.scss */
  .styled-select:before select, .styled-select:after select {
    appearance: button !important; }

/*SLIDER*/
/* line 149, src/sass/print.scss */
.slick-track {
  width: 100% !important;
  transform: translate3d(0, 0, 0) !important; }

/* line 154, src/sass/print.scss */
.slick-slide {
  width: 100% !important; }
  /* line 157, src/sass/print.scss */
  .slick-slide.slick-cloned {
    display: none !important; }

/* line 162, src/sass/print.scss */
.slick-arrow,
.slick-dots {
  display: none !important; }

/* line 169, src/sass/print.scss */
.bk-top-slider .slider-wrapper .slider-slide {
  padding: 0 !important; }
  /* line 172, src/sass/print.scss */
  .bk-top-slider .slider-wrapper .slider-slide .img-wrapper {
    position: relative !important; }

/* line 181, src/sass/print.scss */
.bk-focus {
  display: block !important; }
  /* line 184, src/sass/print.scss */
  .bk-focus .img-wrapper {
    width: 100% !important; }
  /* line 188, src/sass/print.scss */
  .bk-focus .text-wrapper {
    padding-left: 0 !important; }

/* line 195, src/sass/print.scss */
.bk-app .of-cover {
  position: relative !important; }

/* line 202, src/sass/print.scss */
.bk-img-text .-flex-mode {
  display: block !important; }

/* line 207, src/sass/print.scss */
.table-responsive {
  overflow: hidden !important; }

/* line 213, src/sass/print.scss */
.custom-table tr td {
  min-width: inherit !important;
  max-width: 100% !important; }
  /* line 217, src/sass/print.scss */
  .custom-table tr td .td-text {
    max-height: inherit !important;
    padding-right: 0 !important;
    overflow: auto !important; }

/* line 228, src/sass/print.scss */
.norme-detail h1 {
  font-size: 25px !important; }

/* line 232, src/sass/print.scss */
.norme-detail h4 {
  font-size: 22px !important; }
